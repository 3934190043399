<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-margin">
            <div uk-grid class="uk-grid-small">
                <div>
                    <div class="uk-inline">
                        <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                        <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'name')" type="text" placeholder="Search by category name">
                    </div>
                </div>
                <div class="uk-inline">
                  <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showCreateModal"
                  v-if="consist(user_cred.roles, ['admin-bd', 'qwe123'])"
                  >Create</button>
                </div>
            </div>
        </div>
        <div class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-small uk-table-divider uk-table-striped uk-text-center">
                    <thead>
                        <tr>
                            <th class="uk-text-center">Name</th>
                            <th class="uk-text-center"># Question</th>
                            <th class="uk-text-center uk-width-1-4" v-if="consist(user_cred.roles, ['admin-bd', 'qwe123'])">Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="test_categories.docs.length>0">
                        <tr v-for="(category, i) in test_categories.docs" :key="i">
                            <td>{{category.name}}</td>
                            <td>{{category.question_amount}}</td>
                            <td>
                                <button
                                  style="width:100px; cursor:pointer; margin-botton: 10px; height:30px"
                                  class="uk-button-default uk-margin-right"
                                  @click="editTestCategory(category)"
                                  v-if="consist(user_cred.roles, ['admin-bd', 'qwe123'])">
                                  Edit
                                </button>
                                <button
                                  style="width:100px; cursor:pointer; height:30px"
                                  class="uk-button-danger"
                                  @click="showDeleteModal(category)"
                                  v-if="consist(user_cred.roles, ['admin-bd', 'qwe123'])">
                                  Delete
                                </button>
                            </td>
                        </tr>
                    </tbody>
                    <empty-table v-else :colspan="6"/>
                </table>
            </div>
            <pagination
                :total-data="test_categories.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <div id="deleteModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
                <h2 class="uk-modal-title">Delete Test Category</h2>
                <p>Are you sure to delete this test category ?
                    <br>Name : {{category.name}}
                    <br># Question : {{category.question_amount}}
                </p>
                <p class="uk-text-right">
                    <button class="uk-button uk-button-danger" type="button" @click="deleteCategory(category._id)">Delete</button>
                </p>
            </div>
        </div>

        <div id="formCategoryModal" uk-modal esc-close="false" bg-close="false">
          <div class="uk-modal-dialog">
            <button class="uk-modal-close-default" type="button" uk-close @click="hideCreateModal"></button>
            <div class="uk-modal-header">
              <h2 v-if="formIsEdit" class="uk-modal-title">Edit Form</h2>
              <h2 v-else class="uk-modal-title">Create Form</h2>
            </div>
            <div class="uk-modal-body">
              <div class="uk-margin">
                <label class="uk-form-label">Category Name <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('name')}"
                  name="name"
                  type="text"
                  v-model="form.name"
                  v-validate="'required'"
                  placeholder="Category Name">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('name')">{{ errors.first('name') }}</span>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label"># Question <b class="uk-text-danger">*</b></label>
                <input
                  class="uk-input"
                  :class="{'uk-form-danger': errors.has('question_amount')}"
                  name="question_amount"
                  type="number"
                  v-model="form.question_amount"
                  v-validate="'required'"
                  placeholder="Question Amount">
                <span class="uk-text-small uk-text-danger" v-show="errors.has('question_amount')">{{ errors.first('question_amount') }}</span>
              </div>
            </div>
            <div class="uk-modal-footer uk-text-right">
              <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
              <button v-else class="uk-button uk-button-primary" type="button" @click="saveCategory">Save</button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';

export default {
    data() {
        return {
            user_cred: getUserLogin(),
            test_categories: {docs:[]},
            category: {},
            meta: {
                limit: 100,
                page: 1,
                name: ''
            },
            form: {
                name: '',
                question_amount: null,
            },
            isLoading: false,
            isFetching: false,
            formIsEdit: false,
        };
    },
    components: {
        Pagination,
        EmptyTable,
    },
    watch: {
        meta() {
            this.setTestCategoryMeta();
        },
    },
    async mounted() {
        await this.setTestCategoryMeta();
    },
    methods: {
        ...mapActions({
            getTestCategoryLists: 'test_category/getTestCategoryLists',
            updateTestCategory: 'test_category/updateTestCategory',
            deleteTestCategory: 'test_category/deleteTestCategory',
            createTestCategory: 'test_category/createTestCategory',
        }),
        async setTestCategoryMeta() {
            this.test_categories = await this.getTestCategoryLists(this.meta);
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        async hideCreateModal() {
            await window.UIkit.modal('#formCategoryModal').hide();
            this.resetForm();
        },
        editTestCategory(category) {
            this.formIsEdit = true;
            this.fillForm(category);
            this.showCreateModal();
        },
        async saveCategory() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                const response = this.formIsEdit
                    ? await this.updateTestCategory(this.form)
                    : await this.createTestCategory(this.form);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('Test Category saved!');
                    await this.setTestCategoryMeta();
                    this.hideCreateModal();
                } else {
                    notificationDanger('Failed to save test category.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async showCreateModal() {
            await window.UIkit.modal('#formCategoryModal').show();
        },
        fillForm(category) {
            try {
                this.form.id = category._id;
                this.form.name = category.name;
                this.form.question_amount = category.question_amount;
            } catch (err) {
                notificationDanger(err);
            }
        },
        resetForm() {
            this.formIsEdit = false;
            this.form = {
                name: '',
                question_amount: null
            };
            this.$validator.reset();
        },
        async showDeleteModal(category){
            this.category = category;
            await window.UIkit.modal('#deleteModal').show();
        },
        async hideDeleteModal(){
            await window.UIkit.modal('#deleteModal').hide();
            this.category = {};
        },
        async deleteCategory(id){
            try {
                const response = await this.deleteTestCategory(id);
                if (response && response.status === 'OK') {
                    notificationSuccess('Test Category deleted!');
                    await this.setTestCategoryMeta();
                    this.hideDeleteModal();
                } else {
                    notificationDanger('Failed to delete test category.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    },
};
</script>
